"use client";

import { NoxusEmptyBlock } from "@/components/UI/NoxusEmptyBlock";
import { CircleX } from "lucide-react";

const Page404 = () => {
  return (
    <NoxusEmptyBlock
      icon={CircleX}
      title="We could not find this page"
      description="We searched high and low, but could not find what you're looking for."
      isScreenSize
      linkButton="Go to home"
      link="/"
    />
  );
};

export default Page404;
