import { Box, Button, Center, Text } from "@chakra-ui/react";
import { LucideIcon } from "lucide-react";
import { ReactNode } from "react";
import Link from "next/link";

const NoxusEmptyBlock = ({
  title,
  description,
  icon,
  button,
  buttonIcon,
  isScreenSize = false,
  link,
  linkButton,
  onClick,
}: {
  title: string;
  description?: string | ReactNode;
  icon: LucideIcon;
  button?: string;
  buttonIcon?: LucideIcon;
  link?: string;
  linkButton?: string;
  isScreenSize?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <Center
      flexDirection="column"
      flex={1}
      w={isScreenSize ? "100wh" : "full"}
      h={isScreenSize ? "100vh" : "full"}
      gap="1rem"
      padding="3rem"
      alignItems="center"
      textAlign="center"
      color="gray.300"
    >
      <Box as={icon} size="3rem" strokeWidth="2px" />

      <Text textColor="blue.950" textStyle="boldXL">
        {title}
      </Text>

      {description && (
        <Text textColor="gray.500" textStyle="baseM">
          {description}
        </Text>
      )}

      {button && (
        <Button
          variant="primary"
          leftIcon={
            buttonIcon ? (
              <Box
                as={buttonIcon}
                size="1.25rem"
                strokeWidth="1.25px"
                color="#FFFFFF"
              />
            ) : undefined
          }
          onClick={onClick}
        >
          {button}
        </Button>
      )}

      {link && (
        <Link href={link}>
          <Button variant="primary" mt="2rem">
            {linkButton}
          </Button>
        </Link>
      )}
    </Center>
  );
};

export { NoxusEmptyBlock };
